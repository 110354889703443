import React, { useEffect, useState, useRef, Children } from "react";

import "./Navbar.css";

import blackLogo from "../../assets/images/iMuskaanK_black.svg";
import dropdownIcon from "../../assets/icons/dropdown.svg";

import { Squash as Hamburger } from "hamburger-react";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";

const Navbar = () => {
  const routes = [
    { title: "Blogs", link: "/blogs" },
    { title: "English", id: "BEng", link: "/blogs/eng" },
    { title: "Hindi", id: "BHin", link: "/blogs/hin" },
    { title: "Videos", link: "/videos" },
    { title: "English", id: "VEng", link: "/videos/eng" },
    { title: "Hindi", id: "VHin", link: "/videos/hin" },
    { title: "Recommendations", link: "/recommendations" },
    { title: "About Me", link: "#about" },
    { title: "Subscribe", link: "#subscribe" },
    { title: "Contact Me", link: "#contact" },
  ];

  useEffect(() => {
    var nav = document.getElementsByClassName("nav")[0];
    var navLogo = document.getElementsByClassName("nav-logo-container")[0];
    var navLogoMob = document.getElementsByClassName("nav-logo-container")[1];
    var hamburger = document.getElementsByClassName("hamburger")[0];
    if (window.location.pathname === "/") {
      navLogo.style.display = "none";
      navLogoMob.style.display = "none";
    } else {
      navLogo.style.display = "block";
      nav.style.color = "black";
      hamburger.style.color = "black";
      hamburger.style.backgroundImage = "linear-gradient(#ada2ff, #e3dffd)";
      nav.style.backgroundImage = "linear-gradient(#ada2ff, #e3dffd)";
      navLogoMob.style.display = "block";
      navLogo.style.zIndex = "10";
      navLogoMob.style.zIndex = "10";
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.location.pathname === "/") {
        var spacer = document.getElementById("spacer");
        if (window.scrollY < 399.5) {
          if (window.scroll <= 1) {
            spacer.style.display = "none";
          }
          spacer.style.display = "block";
          spacer.style.width = window.scrollY / 20 + "%";
        } else if (window.scrollY >= 399.5) {
          // fix the logo to the top
          spacer.style.width = 399.5 / 20 + "%";
        }
      }
    });
  }, []);

  // add background to navbar after reaching a particular y axis value
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.location.pathname === "/") {
        var nav = document.getElementsByClassName("nav")[0];
        var hamburger = document.getElementsByClassName("hamburger")[0];
        var navLogoMob =
          document.getElementsByClassName("nav-logo-container")[1];
        if (window.scrollY < 1050) {
          nav.style.color = "white";
          hamburger.style.color = "white";
          nav.style.backgroundImage = "";
          navLogoMob.style.display = "none";
          hamburger.style.justifyContent = "flex-end";
          hamburger.style.backgroundImage = "";
        } else if (window.scrollY >= 1050) {
          nav.style.color = "black";
          nav.style.backgroundImage = "linear-gradient(#ada2ff, #e3dffd)";
          hamburger.style.color = "black";
          navLogoMob.style.display = "block";
          hamburger.style.justifyContent = "space-between";
          hamburger.style.backgroundImage = "linear-gradient(#ada2ff, #e3dffd)";
        }
      }
    });
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [blogsDropdown, setBlogsDropdown] = useState(false);
  const [videosDropdown, setVideosDropdown] = useState(false);

  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  return (
    <>
      <nav className="nav">
        <div className="nav-container">
          <a className="blogs" href="/blogs">
            Blogs
            <div className="dropdown">
              <a href="/blogs/eng">English</a>
              <a href="/blogs/hin">Hindi</a>
            </div>
          </a>
          <a className="videos" href="/videos">
            Videos
            <div className="dropdown">
              <a href="/videos/eng">English</a>
              <a href="/videos/hin">Hindi</a>
            </div>
          </a>
          <a className="recommendations" href="/recommendations">
            Recommendations
          </a>
          <a className="nav-logo-container" href="/">
            <img id="nav-logo" src={blackLogo} alt="iMuskaanK" />
          </a>
          <div id="spacer" className="spacer"></div>
          <a className="about" href="/#about">
            About Me
          </a>
          <a className="subscribe" href="#subscribe">
            Subscribe
            {/* Join Our Community */}
          </a>
          <a className="contact" href="/#contact">
            Contact Me
          </a>
        </div>
      </nav>
      <div
        ref={ref}
        className="hamburger"
        style={
          window.location.pathname == "/"
            ? { justifyContent: "flex-end" }
            : { justifyContent: "space-between" }
        }
      >
        <a className="nav-logo-container" href="/">
          <img id="nav-logo" src={blackLogo} alt="iMuskaanK" />
        </a>
        <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.95 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed left-0 shadow-4xl right-0 top-[5rem] p-5 pt-0 bg-neutral-950 fixed z-10"
            >
              <ul id="ul" className="grid gap-2 ">
                {routes.map((route, idx) => {
                  return (
                    ((blogsDropdown &&
                      (route.id === "BEng" || route.id === "BHin")) ||
                      (videosDropdown &&
                        (route.id === "VEng" || route.id === "VHin")) ||
                      !(
                        route.title === "English" || route.title === "Hindi"
                      )) && (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        id={idx}
                        key={route.title}
                        className={`w-full p-[0.08rem] rounded-xl hamburger-item ${
                          route.title === `English` || route.title === `Hindi`
                            ? "bg-violet-200"
                            : ""
                        }`}
                      >
                        <a
                          onClick={() => setOpen((prev) => !prev)}
                          className={
                            "flex items-center justify-between w-full p-5 rounded-xl bg-neutral-950 text-black"
                          }
                          href={route.link}
                        >
                          <span className="flex gap-1 text-lg">
                            {route.title}
                          </span>
                        </a>
                        {route.title === "Blogs" && (
                          <div
                            onClick={() => {
                              setBlogsDropdown((prev) => !prev);
                              setVideosDropdown(false);
                            }}
                          >
                            <img
                              className={`m-5 h-5 ${
                                blogsDropdown
                                  ? "rotate-180 transition"
                                  : "transition"
                              }`}
                              src={dropdownIcon}
                              alt="X"
                            />
                          </div>
                        )}
                        {route.title === "Videos" && (
                          <div
                            onClick={() => {
                              setVideosDropdown((prev) => !prev);
                              setBlogsDropdown(false);
                            }}
                          >
                            <img
                              className={`m-5 h-5 ${
                                videosDropdown
                                  ? "rotate-180 transition"
                                  : "transition"
                              }`}
                              src={dropdownIcon}
                              alt="X"
                            />
                          </div>
                        )}
                      </motion.li>
                    )
                  );
                })}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Navbar;
