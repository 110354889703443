import React from "react";

import "./Featured.css";

import Card from "./Card/Card";
import data from "./Data";

import { useState, useEffect, useRef } from "react";

import chevron from "../../assets/images/chevron.svg";

import chevrons from "../../assets/images/chevrons-right.svg";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

const Featured = (props) => {
  // useEffect(() => {
  //   var slider = document.getElementById("slider");

  //   const handleScroll = () => {
  //     if (slider.scrollLeft == 0) {
  //       document.getElementById("left").style.display = "none";
  //     } else if (slider.scrollLeft == slider.scrollWidth) {
  //       document.getElementById("right").style.display = "none";
  //     } else {
  //       document.getElementById("left").style.display = "block";
  //       document.getElementById("right").style.display = "block";
  //     }
  //     console.log((slider.scrollLeft = "/" + slider.scrollMax));
  //   };
  //   slider.addEventListener("scroll", handleScroll);
  //   return () => {
  //     slider.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [blogData, setBlogData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (props.view === "Videos") {
      fetch(apiUrl + "/videos")
        .then((response) => response.json())
        .then((data) => {
          setVideosData(data);
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching video data:", error));
    } else {
      fetch(apiUrl + "/blogs")
        .then((response) => response.json())
        .then((data) => {
          setBlogData(data);
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching blog data:", error));
    }
  }, []);

  const left = () => {
    var slider = document.getElementById(props.view);
    slider.scrollLeft = slider.scrollLeft - (slider.offsetWidth * 80) / 100;
  };
  const right = () => {
    var slider = document.getElementById(props.view);
    slider.scrollLeft = slider.scrollLeft + (slider.offsetWidth * 80) / 100;
  };

  return (
    <div>
      <div className="featured-heading">{props.title}</div>
      <div className="featured-container">
        <img
          id="left"
          className="chevron inverted"
          src={chevron}
          onClick={left}
        />

        <div id={props.view} className="cards-container">
          {loading
            ? Array.from({ length: 4 }).map((_, index) => (
                <SkeletonLoader key={index} type="card" />
              ))
            : props.view === "Videos"
            ? videosData.map((data) => (
                <Card
                  id={data.id}
                  type="video"
                  key={data.title.replaceAll(/\s/g, "")}
                  image={data.image}
                  heading={data.title}
                  link={data.link}
                  content={data.subtitle}
                />
              ))
            : blogData.map((blog) => (
                <Card
                  key={blog._id}
                  type="blog"
                  id={blog._id}
                  image={blog.image}
                  heading={blog.title}
                  content={blog.subtitle}
                />
              ))}
        </div>
        <img id="right" className="chevron" src={chevron} onClick={right} />
      </div>
      <a className="view-all" href={props.link}>
        View All {props.view}
        <img className="chevrons-right" src={chevrons} />
      </a>
    </div>
  );
};

export default Featured;
