import React from "react";

import "./About.css";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";

import bg from "../../assets/images/baby_bg.jpeg";
import about from "../../assets/images/about.svg";
import { Parallax } from "react-parallax";

const About = () => {
  const windowWidth = window.innerWidth;

  return (
    <div id="about" className="z-10 -mt-4 md:-mt-16">
      <Parallax bgImage={bg} strength={windowWidth < 768 ? 150 : 300}>
        <div className="about-img">
          <img src={about} />
        </div>
      </Parallax>

      {/* <Parallax className="parallax" pages={2}>
        <ParallaxLayer
          speed={0.2}
          span={1}
          style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
        ></ParallaxLayer>
        <ParallaxLayer
          offset={0.14}
          style={{ backgroundImage: `url(${about})`, backgroundSize: "cover" }}
        ></ParallaxLayer>
      </Parallax> */}
      {/* <img src={content} alt="content" className="about-content pt-32" /> */}
      {/* <div className="about-content">
        <div className="upper-content">
          <div className="para">
            Welcome to IMuskaanK, a platform where we discuss and explore all
            aspects of parenting. As an actor, I became interested in parenting
            because while working on the backstories of different characters, I
            realized that a person's inner voice and self-talk come from how
            they were parented. How our parents made us feel as kids - whether
            they listened to us, saw us, or criticized us - shapes our self-love
            and self-talk. This, in turn, affects how we react to things.
          </div>
          <div className="para">
            I believe true healing in my life started with me learning how to
            re-parent my inner child by having more self-love and a healthier
            relationship with myself. This experience inspires me to have the
            same relationship with my future children. As individuals, whether
            we are parents or not, we need access to these ideas that can help
            us do the same.
          </div>
        </div>
        <div className="lower-content">
          <div className="left-para">
            At IMuskaanK, our goal is to provide informative, engaging, and
            reliable content that helps parents and aspiring parents navigate
            the challenges and joys of raising children. We understand that
            parenting is a rewarding yet challenging journey, and we're here to
            help. Our team of experienced writers and parenting experts cover a
            wide range of topics, including pregnancy, infant care, toddlerhood,
            childhood, adolescence, and beyond. We also provide advice on topics
            such as discipline, education, nutrition, mental health, and more.
          </div>
          <div className="right-para">
            We believe that all parents should have access to high-quality
            information and resources that can help them make informed decisions
            about their families. That's why we strive to provide content that
            is based on the latest research, best practices, and expert
            opinions. Thank you for choosing IMuskaanK as your trusted source
            for parenting information. We look forward to helping you develop
            your parenting skills and navigate the wonderful world of parenthood
            while also supporting your healing journey.
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default About;
