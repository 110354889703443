import React, { useEffect, useRef } from "react";

import "./Landing.css";

import whiteLogo from "../../assets/images/iMuskaanK.svg";
import blackLogo from "../../assets/images/iMuskaanK_black.svg";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import youtube from "../../assets/images/youtube.svg";
import twitter from "../../assets/images/twitter.svg";

const Landing = () => {
  const previous = useRef(-1);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      var logo = document.getElementById("logo");
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      if (window.scrollY >= 1235) {
        logo.src = blackLogo;
      } else {
        logo.src = whiteLogo;
      }
      if (vw > 768) {
        if (window.scrollY > previous.current && window.scrollY < 399.5) {
          logo.style.position = "block";
          logo.style.top = null;
          logo.style.marginTop = null;
          if (vw < 768) {
            logo.style.transform = "translateY(-" + window.scrollY / 4 + "%)";
            logo.style.width = 75 - window.scrollY / 10 + "vw";
          } else {
            logo.style.transform = "translateY(-" + window.scrollY / 4 + "%)";
            logo.style.width = 50 - window.scrollY / 10 + "vw";
          }
        } else if (
          window.scrollY <= previous.current &&
          window.scrollY < 399.5
        ) {
          logo.style.position = "block";
          logo.style.transform =
            "translateY(" + (399.5 / 4 - window.scrollY / 4) + "%)";
          logo.style.width = 50 - window.scrollY / 10 + "vw";
        } else {
          previous.current = window.scrollY;
          logo.style.position = "fixed";
          logo.style.top = "0";
          logo.style.marginTop = "0.5rem";
          logo.style.transform = "translateY(0%)";
          logo.style.width = 50 - 399.5 / 10 + "vw";
        }
      } else {
        if (window.scrollY > previous.current && window.scrollY < 319.5) {
          logo.style.position = "block";
          logo.style.top = null;
          logo.style.marginTop = null;
          logo.style.transform = "translateY(-" + window.scrollY / 1.5 + "%)";
          logo.style.width = 75 - window.scrollY / 6 + "vw";
        } else if (
          window.scrollY <= previous.current &&
          window.scrollY < 319.5
        ) {
          logo.style.position = "block";
          logo.style.transform =
            "translateY(" + (319.5 / 1.2 - window.scrollY / 1.5) + "%)";
          logo.style.width = 75 - window.scrollY / 6 + "vw";
        } else {
          previous.current = window.scrollY;
          logo.style.position = "fixed";
          logo.style.top = "0";
          logo.style.marginTop = "0.5rem";
          logo.style.transform = "translateY(0%)";
          logo.style.width = 75 - 319.5 / 6 + "vw";
        }
        if (window.scrollY < 1050) {
          logo.style.display = "block";
        } else if (window.scrollY >= 1050) {
          logo.style.display = "none";
        }
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var socials = document.getElementsByClassName("socials")[0];
      if (window.scrollY < 399.5) {
        socials.style.display = "flex";
        socials.style.transform = "translateY(-" + window.scrollY / 20 + "%)";
        socials.style.opacity = 1 - window.scrollY / 300;
      } else if (window.scrollY >= 399.5) {
        socials.style.display = "none";
        socials.style.opacity = 0;
      }
    });
  }, []);

  return (
    <div>
      <div className="main-container">
        <a className="title" href="#">
          <img id="logo" src={whiteLogo} alt="iMuskaanK" />
        </a>
        <div className="socials">
          <a target="_blank" href="https://www.facebook.com/iMuskaanK/">
            <img className="icon" src={facebook} alt="facebook" />
          </a>
          <a target="_blank" href="https://www.instagram.com/imuskaank/">
            <img className="icon" src={instagram} alt="instagram" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCbSa7cwea9Qh62MIe2QKfHg"
          >
            <img className="icon" src={youtube} alt="youtube" />
          </a>
          <a target="_blank" href="https://twitter.com/imuskaank">
            <img className="icon" src={twitter} alt="twitter" />
          </a>
        </div>
      </div>
      <div className="grad"></div>
    </div>
  );
};

export default Landing;
