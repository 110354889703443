import React from "react";

import "./Card.css";

const Card = (props) => {
  return (
    <div>
      <div className="card-container">
        <div className="card-image">
          <img src={props.image}></img>
        </div>
        <div className="card-heading">{props.heading}</div>
        <div className="card-content">{props.content}</div>
        {props.type == "blog" ? (
          <a href={"/blog/" + props.id}>
            <button className="read-more" type={"submit"}>
              Read More
            </button>
          </a>
        ) : (
          <a href={props.link} target="_blank">
            <button className="read-more" type={"submit"}>
              Watch Video
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
