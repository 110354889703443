import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/Navbar/Navbar";
import Landing from "./components/Landing/Landing";
import About from "./components/About/About";
import Featured from "./components/Featured/Featured";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Content from "./components/Content/Content";
import Recommendations from "./components/Recommendations/Recommendations";
import Blog from "./components/Blog/Blog";
import NotFound404 from "./components/404/404";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import ComingSoon from "./components/ComingSoon/ComingSoon";

function App() {
  if (window.location.pathname === "/ ") {
    // document.getElementsByTagName("nav")[0].style.display = "none";
    console.log("Landing page");
  }
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Navbar /> */}
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Landing />
                <About />
                <Featured title="Featured Blogs" view="Blogs" link="/blogs" />
                <Featured
                  title="Featured Videos"
                  view="Videos"
                  link="/videos"
                />
                <Contact />
                <Footer />
              </>
            }
            exact
          />

          <Route
            path="/recommendations"
            element={
              <>
                <Navbar />
                {/* <Recommendations /> */}
                <ComingSoon />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/blogs"
            element={
              <>
                <Navbar />
                <Content heading="Blog" />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/blogs/:language"
            element={
              <>
                <Navbar />
                <Content heading="Blog" />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/videos"
            element={
              <>
                <Navbar />
                <Content heading="Video" />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/videos/:language"
            element={
              <>
                <Navbar />
                <Content heading="Video" />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/blog/:id"
            element={
              <>
                <Navbar />
                <Blog />
                <Footer />
              </>
            }
            exact
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Navbar />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route path="/unsubscribe/:text" element={<Unsubscribe />} />
          <Route
            path="/pdf"
            element={
              <>
                <Navbar />
                <Footer />
              </>
            }
            exact
          />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
