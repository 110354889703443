// import temp from "../../assets/images/temp.svg";
import temp from "../../assets/images/sample_box.svg";

export default [
  {
    id: "1",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "2",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "3",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "4",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "5",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "6",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
  {
    id: "7",
    image: `${temp}`,
    heading: "Sample Heading",
    content:
      "In my quest to know more, I enrolled in the first level of the RIE professional training. I had no intention of ever teaching parenting classes. The more I learned, however, the more captivated I became by Magda’s philosophy.",
  },
];
