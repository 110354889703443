import React from "react";
import { Link } from "react-router-dom";

import "./404.css";

const NotFound404 = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-heading">404</h1>
      <div className="not-found-content">
        The page you are looking for does not exist.
      </div>
      <Link to="/">
        <div className="not-found-link">Go back to home</div>
      </Link>
    </div>
  );
};

export default NotFound404;
