import React from "react";

import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <div className="comingsoon-wrapper">
      <div className="comingsoon-heading">Coming Soon!</div>
    </div>
  );
};

export default ComingSoon;
