import React from "react";
import baby from "../../assets/images/baby.gif";

import "./NoContent.css";

const NoContent = ({ view }) => {
  return (
    <div
      className={`no-content-container ${view == "content" ? "h-auto" : ""}`}
    >
      <div className="no-content-image-wrapper">
        <img src={baby} />
      </div>
      <h2 className="no-content-heading">No Content Found</h2>
    </div>
  );
};

export default NoContent;
