import React, { useState } from "react";
import { useEffect } from "react";

import "./Footer.css";

import facebook from "../../assets/images/facebook_footer.svg";
import instagram from "../../assets/images/instagram_footer.svg";
import youtube from "../../assets/images/youtube_footer.svg";
import twitter from "../../assets/images/twitter_footer.svg";
import logo from "../../assets/images/footer_logo.svg";
import circle from "../../assets/images/footer_circle.png";
import Lottie from "react-lottie";
import loader from "../../assets/lotties/loader.json";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const pdf = useLocation().pathname.includes("pdf");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var circle = document.getElementById("circle");
      circle.style.transform = "rotate(-" + window.scrollY / 10 + "deg)";
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    pdf: pdf,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(apiUrl + "/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setLoading(false);
        setFormData({
          name: "",
          email: "",
          pdf: pdf,
        });
        toast(await response.text(), {
          type: response.status == 200 ? "success" : "info",
          style: { backgroundColor: "#fff8e1" },
        });
      } else {
        setLoading(false);
        setFormData({
          name: "",
          email: "",
        });
        throw new Error("Failed to subscribe");
      }
    } catch (error) {
      setLoading(false);
      setFormData({
        name: "",
        email: "",
      });
      console.error("Error subscribing:", error);
      toast.error("Error occurred while subscribing", {
        style: { backgroundColor: "#fff8e1" },
      });
    }
  };

  return (
    <div>
      <div className="bottom-grad"></div>
      <div className="bottom-container">
        {!pdf && (
          <div className="footer-logo">
            <img
              id="image"
              className="footer-logo-image"
              src={logo}
              alt="logo"
            />
            <img
              id="circle"
              className="footer-logo-circle"
              src={circle}
              alt="logo"
            />
          </div>
        )}
        <div id="subscribe" className="subscribe-container">
          <div className="subscribe-left">
            <div className="subscribe-heading">
              {/* Subscribe  */}
              {pdf
                ? `You’ll receive a FREE PDF about DEVELOPMENTAL MILESTONES FOR BABIES IN THEIR FIRST YEAR on your email`
                : `For a weekly update from iMuskaanK, join the community to stay updated on new articles and videos!`}
            </div>
            {/* <div className="subscribe-content">
              Enter your Name and Email to get added to our mailing list to
              never miss out on the updates!
            </div> */}
          </div>
          <div className="line"></div>
          <div className="subscribe-right">
            <form className="subscribe-form" onSubmit={handleSubmit}>
              <input
                className="box"
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                disabled={loading}
                required
              />
              <input
                className="box"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                disabled={loading}
                required
              />
              <button
                className="submit box"
                type="submit"
                style={{ cursor: loading ? "not-allowed" : "pointer" }}
                disabled={loading}
              >
                {loading ? (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: loader,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={150}
                    width={150}
                  />
                ) : pdf ? (
                  "Send PDF"
                ) : (
                  "Subscribe"
                )}
              </button>
              <div className="subscribe-content">
                {!pdf && "(We respect your privacy, unsubscribe anytime)"}
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
        <div className="footer-socials-container">
          <div className="follow">Follow for new and exclusive content!</div>
          <div className="footer-socials">
            <a target="_blank" href="https://www.facebook.com/iMuskaanK/">
              <img className="icon" src={facebook} alt="facebook" />
            </a>
            <a target="_blank" href="https://www.instagram.com/imuskaank/">
              <img className="icon" src={instagram} alt="instagram" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCbSa7cwea9Qh62MIe2QKfHg"
            >
              <img className="icon" src={youtube} alt="youtube" />
            </a>
            <a target="_blank" href="https://twitter.com/imuskaank">
              <img className="icon" src={twitter} alt="twitter" />
            </a>
          </div>
        </div>
        <div className="footer-note">
          <div className="left-note">© 2024 iMuskaanK</div>
          <div className="line"></div>
          <a className="right-note" href="/privacy-policy">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
