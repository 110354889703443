import React, { useState } from "react";

import "./Contact.css";

import image from "../../assets/images/meet_muskaan.svg";

const Contact = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl + "/send-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        alert("Message sent successfully");
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error occurred while sending message");
    }
  };

  return (
    <div id="contact" className="-mt-4 pt-4">
      <div className="contact-container">
        <div className="left">
          <div className="content">
            {/* Lorem Ipsum Dipsum Lorem Ipsum Dipsum Lorem Ipsum Dipsum Lorem Ipsum
            Dipsum Lorem Ipsum Dipsum Lorem Ipsum Dipsum Lorem Ipsum Dipsum
          Lorem Ipsum Dipsums */}
            <img src={image} />
          </div>
        </div>
        <div className="right">
          <div className="heading">Meet Muskaan</div>
          {/* <form className="form-container" onSubmit={handleSubmit}>
            <input
              className="box"
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              className="box"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              className="box"
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <textarea
              className="box"
              rows={8}
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button className="send box" type="submit">
              Send Message
            </button>
          </form> */}
          As an actor, I've delved into characters' backstories, realizing how
          childhood experiences shape our inner voice and self-talk. My healing
          journey began with re-parenting my inner child, fostering self-love
          and a healthier relationship with myself. After delivering my first
          baby, I was motivated to delve deeper into parenting dynamics and
          create insightful content. <br /> <br />
          At IMuskaanK, we offer concise, informative content to help parents
          navigate the joys and challenges of raising children. Join me in my
          parenting journey.
        </div>
      </div>
    </div>
  );
};

export default Contact;
