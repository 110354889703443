import React from "react";
import "./SkeletonLoader.css";

const SkeletonLoader = ({ type }) => {
  return (
    <div>
      <div className={`${type == "card" ? "skeleton-container" : ""}`}>
        <div
          className={`skeleton-image ${type == "box" && "w-auto h-80 m-20"}`}
        ></div>
        {type == "card" && (
          <>
            <div className="skeleton-heading"></div>
            <div className="skeleton-content"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default SkeletonLoader;
