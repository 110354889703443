import React, { useEffect, useState } from "react";

import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container mt-24">
      <div className="privacy-policy-heading">Privacy Policy</div>
      <ol type="1">
        <li>
          <div className="privacy-policy-content-heading">
            1. Information We Collect:
          </div>
          <ul>
            <li>
              <strong>Personal Information:</strong> We collect your name and
              email address when you visit our website or subscribe to our
              mailing list.
            </li>
            <li>
              <strong>Usage Data:</strong> We gather information about your
              browsing behavior on our site, such as your IP address and pages
              visited.
            </li>
            <li>
              <strong>Cookies:</strong> We use cookies to enhance your browsing
              experience and analyze website traffic.
            </li>
          </ul>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            2. Use of Information:
          </div>
          <p>
            We use collected information to improve our services, personalize
            your experience, and send promotional emails about relevant content
            or offers.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            3. Sharing of Information:
          </div>
          <p>
            We do not sell your personal information. We may share it with
            trusted service providers to operate our website or respond to legal
            requests.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            4. Data Security:
          </div>
          <p>
            While we take measures to protect your information, we cannot
            guarantee absolute security due to the nature of online
            transmission.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            5. Third-Party Links:
          </div>
          <p>
            We are not responsible for the privacy practices of third-party
            websites linked on our site.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            6. Children's Privacy:
          </div>
          <p>
            Our site is not intended for children under 13. We do not knowingly
            collect their personal information.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">
            7. Changes to Policy:
          </div>
          <p>
            We may update our policy periodically. Changes will be posted on our
            website.
          </p>
        </li>
        <li>
          <div className="privacy-policy-content-heading">8. Contact Us:</div>
          <p>
            For questions or concerns about our policy, contact us at{" "}
            <a href="mailto:imuskaank@gmail.com">imuskaank@gmail.com</a>.
          </p>
        </li>
      </ol>
      <div className="mt-12">
        By using our website, you agree to the terms outlined in this Privacy
        Policy.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
