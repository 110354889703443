import React from "react";

import "./Box.css";

import arrows from "../../../assets/images/arrow-right-circle.svg";

import { useNavigate } from "react-router-dom";

const Box = (props, index) => {
  const navigate = useNavigate();
  const handleClick = (link) => {
    console.log(link);
    navigate(`/${link}`);
  };

  return (
    <div>
      <div
        className={
          props.index % 2 === 0
            ? "box-container left-right"
            : "box-container right-left"
        }
      >
        <div className="box-image-side">
          <img className="box-image" src={props.image} />
        </div>
        <div className="box-text-side">
          <div className="box-heading">{props.heading}</div>
          <div className="box-line" />
          <div className="box-content">{props.content}</div>
          <button
            className="read-full"
            type={"submit"}
            onClick={() => {
              props.read == "Blog"
                ? handleClick("blog/" + props.id)
                : window.open(props.link, "_blank");
            }}
          >
            <div></div>
            {props.read == "Blog" ? `Read Full ${props.read}` : `Watch Video`}
            <img className="arrows" src={arrows} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Box;
