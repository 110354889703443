import React, { useEffect, useState } from "react";

import "./Blog.css";
import temp from "../../assets/images/sample_article.svg";
import cross from "../../assets/images/cross.svg";

import RecentBox from "./RecentBox/RecentBox";

import { Link, useParams } from "react-router-dom";
import { createRoot } from "react-dom/client";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import NoContent from "../NoContent/NoContent";

const Blog = () => {
  const { id } = useParams();

  const [blogData, setBlogData] = useState([]);
  const [recentData, setRecentData] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(apiUrl + "/blogs")
      .then((response) => response.json())
      .then((data) => setBlogData(data.find((blog) => blog._id == id)))
      .catch((error) => console.error("Error fetching blog data:", error));

    fetch(apiUrl + "/recents/blogs")
      .then((response) => response.json())
      .then((data) => setRecentData(data))
      .catch((error) => console.error("Error fetching recent data:", error));
  }, []);

  if (!blogData) {
    return <NoContent view="blog" />;
  }

  const { title, subtitle, image, content } = blogData;
  return blogData.length != 0 ? (
    <div className="blog-container">
      <div className="blog-heading-container">
        <Link to="/blogs">
          <img className="cross" src={cross} alt="cross" />
        </Link>
        <div className="blog-heading">
          <div className="blog-title">{title}</div>
          <div className="blog-subtitle">{subtitle}</div>
        </div>
        <img className="blog-img" src={image} alt="blog" />
      </div>
      <div className="blog-body-container">
        <div className="blog-recents-container">
          <div className="blog-recents-body">
            <div className="blog-recents-heading">Recent Blogs</div>
            <div className="blog-recents-content">
              {recentData.map((data) => (
                <Link to={`/blog/${data._id}`} reloadDocument>
                  <RecentBox
                    key={data._id}
                    id={data._id}
                    image={data.image}
                    heading={data.title}
                    content={data.subtitle}
                  />
                </Link>
              ))}
            </div>
          </div>
          <div className="vertical-line"></div>
          <div></div>
          {/* </div> */}
        </div>
        <div className="blog-body">
          {/* <div className="blog-subheading">Blog sub-heading</div> */}

          <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
            {content && content.replace(/\\n/g, "\n")}
          </Markdown>

          {/* <div className="blog-content">{content}</div> */}
        </div>
      </div>
    </div>
  ) : (
    <NoContent view="blog" />
  );
};

export default Blog;
