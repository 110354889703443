import React from "react";

import "./RecentBox.css";

const RecentBox = (props) => {
  return (
    <div>
      <div className="recent-box-container" onClick={""}>
        <div className="recent-box-image-side">
          <img className="recent-box-image" src={props.image} />
        </div>
        <div className="recent-box-text-side">
          <div className="recent-box-heading">{props.heading}</div>
          <div className="recent-box-content">{props.content}</div>
        </div>
      </div>
    </div>
  );
};

export default RecentBox;
