// import temp from "../../assets/images/temp.svg";
import temp from "../../assets/images/sample_box.svg";

export default [
  {
    id: "1",
    type: "blog",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "2",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "3",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "4",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "5",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "6",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "7",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "8",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "9",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "10",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "11",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "12",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "13",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
  {
    id: "14",
    image: `${temp}`,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    link: "https://www.amazon.in/Cat-Care-Airpods-Pro-2019/dp/B0BFXPS8JG/?_encoding=UTF8&pd_rd_w=iEexU&content-id=amzn1.sym.e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_p=e932aeaf-89ea-47b8-9c31-e92696d33d85&pf_rd_r=W75ZNBJVAN6DH6YNFY4G&pd_rd_wg=j5w0E&pd_rd_r=a3a60d89-e966-4be4-9751-45a69a9de4ad&ref_=pd_gw_ci_mcx_mr_hp_d",
  },
];
