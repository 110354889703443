import React, { useState } from "react";
import { useParams } from "react-router-dom";

import "./Unsubscribe.css";

import Lottie from "react-lottie";
import loader from "../../assets/lotties/loader.json";
import { ToastContainer, toast } from "react-toastify";

const Unsubscribe = () => {
  const { text } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl + "/unsubscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: text }),
      });
      if (response.ok) {
        setLoading(false);
        toast(await response.text(), {
          type: response.status == 200 ? "success" : "info",
          style: { backgroundColor: "#fff8e1" },
          onClose: () => {
            window.open("about:blank", "_self");
            window.close();
          },
        });
      } else {
        setLoading(false);
        throw new Error("Failed to unsubscribe");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error unsubscribing:", error);
      toast.error("Error occurred while subscribing", {
        style: { backgroundColor: "#fff8e1" },
        onClose: () => {
          window.open("about:blank", "_self");
          window.close();
        },
      });
    }
  };
  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-content">
        Are you sure you want to unsubscribe from our newsletter?
      </div>
      <button
        className="unsubscribe-link"
        onClick={handleSubmit}
        disabled={loading}
        style={{ cursor: loading ? "not-allowed" : "pointer" }}
      >
        {loading ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loader,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={150}
            width={150}
          />
        ) : (
          "Unsubscribe"
        )}
      </button>
      <ToastContainer />
    </div>
  );
};

export default Unsubscribe;
