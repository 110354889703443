import React, { useEffect, useState } from "react";
import Box from "./Box/Box";
import SkeletonCard from "../SkeletonLoader/SkeletonLoader";

import "./Content.css";
import { useParams } from "react-router-dom";
import NoContent from "../NoContent/NoContent";

const Content = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [blogData, setBlogData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { language } = useParams();

  useEffect(() => {
    if (props.heading == "Video") {
      if (language) {
        fetch(apiUrl + "/videos/" + language)
          .then((response) => response.json())
          .then((data) => {
            setVideosData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching videos data:", error);
            setLoading(false);
          });
      } else {
        fetch(apiUrl + "/videos")
          .then((response) => response.json())
          .then((data) => {
            setVideosData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching videos data:", error);
            setLoading(false);
          });
      }
    } else {
      if (language) {
        fetch(apiUrl + "/blogs/" + language)
          .then((response) => response.json())
          .then((data) => {
            setBlogData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching blogs data:", error);
            setLoading(false);
          });
      } else {
        fetch(apiUrl + "/blogs")
          .then((response) => response.json())
          .then((data) => {
            setBlogData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching blogs data:", error);
            setLoading(false);
          });
      }
    }
  }, []);

  console.log(videosData.length);
  return (
    <div>
      <div className="content-container mt-24">
        <div className="content-heading">
          {(language == "hin"
            ? "Hindi "
            : language == "eng"
            ? "English "
            : "") + `${props.heading}s`}
        </div>
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <SkeletonCard key={index} type="box" />
          ))
        ) : props.heading == "Video" && videosData.length == 0 ? (
          <NoContent view="content" />
        ) : props.heading == "Blog" && blogData.length == 0 ? (
          <NoContent view="content" />
        ) : props.heading == "Video" ? (
          videosData.map((content, index) => (
            <Box
              index={index}
              key={content.title.replaceAll(/\s/g, "")}
              image={content.image}
              heading={content.title}
              content={content.subtitle}
              link={content.link}
              read={props.heading}
            />
          ))
        ) : (
          blogData.map((content, index) => (
            <Box
              index={index}
              key={content._id}
              id={content._id}
              image={content.image}
              heading={content.title}
              content={content.subtitle}
              read={props.heading}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Content;
